import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';

import { toast } from 'react-toastify';

import ballWallLogo from "../../media/icons/ballWallLogo.png";
import { useNavigate } from "react-router-dom";

export const ShowContactUs = () => {
    const form = useRef();
    const history = useNavigate();

    const SuccessMsg = ({ closeToast, toastProps }) => (
        <div className='text-start'>
            Thanks for your message.
            <br />
            We'll reply soon.
        </div>
    )

    const ErrorMsg = ({ closeToast, toastProps }) => (
        <div className='text-start'>
            Someting went wrong.
            <br />
            Please try again later.
        </div>
    )

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_j6hox3c',
            'template_kt6aobi',
            form.current,
            '6mgEf7gJcQu05GIlP'
        )
            .then((result) => {
                console.log(result.text);
                toast.success(<SuccessMsg />);
                return history("/");
            }, (error) => {
                console.log(error.text);
                toast.error(<ErrorMsg />);
            });
        e.target.reset()
    };

    return (
        <div className='row container min-vh-100 p-2 text-center m-auto'>
            <div className='card col-md-6 col-sm-12 p-3 m-auto mt-3 bg-light shadow'>
                <div>
                    <h2 className='text-center'>Contact Us</h2>
                    <img
                        src={ballWallLogo}
                        className="card-img img-fluid m-3 text-center"
                        alt="..."
                        style={{ width: "80px" }}
                    />
                </div>
                <form className='form' ref={form} onSubmit={sendEmail}>
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="userName">Name</label>
                        <input required name='user_name' type="text" id="userName" className="form-control" />
                    </div>
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="userEmail">Email address</label>
                        <input required name='user_email' type="email" id="userEmail" className="form-control" />
                    </div>
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="userSubject">Subject</label>
                        <input required name='subject' type="text" id="userSubject" className="form-control" />
                    </div>
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="userMessage">Message</label>
                        <textarea required name='message' className="form-control" id="userMessage" rows="4"></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary btn-block mb-4 ps-5 pe-5">Send</button>
                </form>
            </div>
        </div>
    )
}

export default ShowContactUs;