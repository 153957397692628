import React, { useContext, useEffect } from "react";
import "./App.css";

// Notifications
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
// import UserContext from "./store/User/UserContext";

// Custom Components
// import Nav from "./components/nav";
import NavPublic from "./components/NavPublic.js";

// Pages
import ShowHome from "./pages/home/ShowHome";
import ShowBallWall from "./pages/ballWall/ShowBallWall";
import ShowPrivacy from "./pages/ballWall/ShowPrivacy";
import ShowContactUs from "./pages/contactUs/ShowContactUs";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";

// States
// import AssemblyTypeState from "./store/AssemblyType/AssemblyTypeState";

//GOOGLE ANALYTICS
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ShowPrivacyWeb from "./pages/privacy/ShowPrivacyWeb";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCzVoF1li0R1VgUAo1WM3VoyoT07UsiQgA",
    authDomain: "wallball.firebaseapp.com",
    databaseURL: "https://wallball.firebaseio.com",
    projectId: "wallball",
    storageBucket: "wallball.appspot.com",
    messagingSenderId: "139202632413",
    appId: "1:139202632413:web:1ada649b966da96e6ad934",
    measurementId: "G-V91W0SHT56"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <NavPublic />
                <div className="bg bg-opacity-10" style={{backgroundColor: "honeydew", opacity: "opacity-10"}}>
                    <ScrollToTop>
                        <Routes>
                            <Route path="/" element={<ShowHome />} />
                            <Route
                                path="/ballwall"
                                element={<ShowBallWall />}
                            />
                            <Route
                                path="/ballwall/privacy"
                                element={<ShowPrivacy />}
                            />
                            <Route
                                path="/contactus"
                                element={<ShowContactUs />}
                            />
                            <Route
                                path="/privacy"
                                element={<ShowPrivacyWeb />}
                            />
                        </Routes>
                    </ScrollToTop>
                </div>
                <Footer />
            </BrowserRouter>
            <ToastContainer />
        </div>
    );
}

export default App;
