import React, { useContext } from "react";
import Post from "./Post";

// import Table from "./TableAssembly";
// import CustomModal from "../../components/CustomModal";
// import CreateAssembly from "./CreateAssembly";

// import AssemblyContext from "../../store/Assembly/AssemblyContext";

// IMAGES
import img from "../../media/photos/ball_wall_game.png";
import imgAndroid from "../../media/photos/ball_wall_game_android.png";

const ShowHome = () => {
    // const {
    //     assembly,
    //     createAssembly,
    //     unsetAssembly,
    //     getAssemblys,
    // } = useContext(AssemblyContext);

    return (
        <div className="container">
            <div className="min-vh-100 pt-4 pb-4">
                <Post
                    header="Ball Wall Game Released for Android"
                    message="Enter the world of Ball Wall and conquer all the levels, unlock characters, stages and beat your friends record. Now Available for Android!"
                    btnTitle="More"
                    btnType="btn btn-success"
                    btnLink="/ballwall"
                    img={imgAndroid}
                />
                <Post
                    header="Ball Wall gameplay Trailer"
                    message="Check out the new YouTube channel for KeykaGames."
                    btnTitle="Go to channel"
                    btnType="btn btn-danger"
                    btnExternalLink="https://www.youtube.com/channel/UCwJwmuMFJ103WK6ufn0eitg"
                    video="https://www.youtube.com/embed/_EogyeFqHj4"
                />
                <Post
                    header="Ball Wall Released for iOS"
                    message="Finally is here!. Play the new game for iOS. Click Here for more details."
                    btnTitle="More"
                    btnType="btn btn-primary"
                    btnLink="/ballwall"
                    img={img}
                />
                <Post
                    header="Ball Wall Privacy Policy"
                    message="Privacy Policy moved to BallWall's page, check it out."
                    btnTitle="Open"
                    btnType="btn btn-primary"
                    btnLink="/ballwall/privacy"
                />
            </div>

            {/* <CustomModal
                            title={"Create Assembly"}
                            handleSubmit={handleCreate}
                            unsetInfo={unsetAssembly}
                            btnModalType={"primary"}
                            btnModalText={<i className="fa-solid fa-plus"></i>}
                            btnFooterType={"primary"}
                            btnFooterText={"Create"}
                        >
                            <CreateAssembly />
                        </CustomModal> */}
            {/* <Table /> */}
        </div>
    );
};

export default ShowHome;
