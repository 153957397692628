import React from "react";

function Footer() {
    return (
        <footer className="shadow-lg pt-3 py-0 my-1 border-top bg-dark bg-gradient text-center text-white mb-0 mt-0">
            <div className="container pt-3 pb-0">
                <section className="mb-4">
                    <a className="btn btn-outline-danger btn-floating me-2"
                        href="https://www.youtube.com/channel/UCwJwmuMFJ103WK6ufn0eitg"
                        target="_blank" rel="noopener noreferrer"
                        role="button"
                    // style={{ width: "4em" }}
                    >
                        <i className="bi bi-youtube"></i>
                    </a>
                    <a className="btn btn-outline-primary btn-floating ms-2 me-2"
                        href="https://www.facebook.com/profile.php?id=100082603402428"
                        target="_blank"
                        rel="noopener noreferrer"
                        role="button"
                    // style={{ width: "4em" }}
                    >
                        <i className="bi bi-facebook"></i>
                    </a>
                    <a className="btn btn-outline-info btn-floating ms-2 me-2"
                        href="https://www.instagram.com/keykagames/"
                        target="_blank"
                        rel="noopener noreferrer"
                        role="button"
                    // style={{ width: "4em" }}
                    >
                        <i className="bi bi-instagram"></i>
                    </a>
                    <a className="btn btn-outline-light btn-floating ms-2 me-2"
                        href="https://www.tiktok.com/@keykagames.com"
                        target="_blank" rel="noopener noreferrer"
                        role="button"
                    // style={{ width: "4em" }}
                    >
                        <i className="bi bi-tiktok"></i>
                    </a>
                    <a className="btn btn-patreon btn-floating ms-2"
                        style={{ width: "42px", height: "38px" }}
                        href="https://www.patreon.com/keykagames"
                        target="_blank" rel="noopener noreferrer"
                        role="button"
                    // style={{ width: "4em" }}
                    >
                        <i className="bi"></i>
                    </a>
                </section>
            </div>
            <p className="text-white text-center bg-dark mb-0 mt-4 py-3">© 2023 KeykaGames</p>
        </footer >
    );
}

export default Footer;