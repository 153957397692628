import React from "react";
import Button from "../../components/Button";

function Post({ header, message, btnTitle, btnType, btnLink, btnExternalLink, img, video }) {
    return (
        <div className="shadow p-3 mb-4 bg-light rounded-3">
            <div className="row text-start">
                {video ? (
                    <>
                        <div className="col-sm-12 col-md-3 p-3">
                            <div className="ratio ratio-1x1">
                                <iframe src={video} title="YouTube video" allowFullScreen></iframe>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-9 p-3">
                            <div className="container-fluid py-3 h-100">
                                <h1 className="display-5 fw-bold">{header}</h1>
                                <p className="fs-4">{message}</p>
                                <Button
                                    title={btnTitle}
                                    type={btnType}
                                    link={btnLink}
                                    externalLink={btnExternalLink}
                                />
                            </div>
                            {/* <p className="text-end mb-0">by: KeykaGames</p> */}
                        </div>
                    </>
                ) : (
                    <>
                        {img && (
                            <>
                                <div className="col-sm-12 col-md-3 p-5">
                                    <img
                                        src={img}
                                        className="card-img img-fluid w-100 rounded-4"
                                        alt="..."
                                    />
                                </div>
                                <div className="col-sm-12 col-md-9 p-3">
                                    <div className="container-fluid py-3 h-100">
                                        <h1 className="display-5 fw-bold">{header}</h1>
                                        <p className="fs-4">{message}</p>
                                        <Button
                                            title={btnTitle}
                                            type={btnType}
                                            link={btnLink}
                                            externalLink={btnExternalLink}
                                        />
                                    </div>
                                    {/* <p className="text-end mb-0">by: KeykaGames</p> */}
                                </div>
                            </>
                        )
                        }
                        {!img && (
                            <>
                                <div className="col-sm-12 col-md-12 p-3 text-center">
                                    <div className="container-fluid py-3 h-100">
                                        <h1 className="display-5 fw-bold">{header}</h1>
                                        <p className="fs-4">{message}</p>
                                        <Button
                                            title={btnTitle}
                                            type={btnType}
                                            link={btnLink}
                                            externalLink={btnExternalLink}
                                        />
                                    </div>
                                    {/* <p className="text-end mb-0">by: KeykaGames</p> */}
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default Post;