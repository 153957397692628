import React, { useContext } from "react";

// IMAGES
import island from "../../media/photos/backgrounds/island.gif";
import logo from "../../media/photos/backgrounds/logo_ballwall.png";
import stage from "../../media/icons/stage.png";
import coin from "../../media/icons/coin.png";
import iceColors from "../../media/icons/ice_colors.png";
import player from "../../media/icons/player.png";
import iphoneCharacter from "../../media/misc/iphone_character.png";
import iphoneMenu from "../../media/misc/iphone_menu.png";
import iphoneStage from "../../media/misc/iphone_stage.png";
import downloadAppStore from "../../media/icons/download_app_store.svg";
import downloadGooglePlay from "../../media/icons/download_google_play.png";
import ballWallLogo from "../../media/icons/ballWallLogo.png";
import { Link } from "react-router-dom";

import "./showBallWall.css";

const ShowBallWall = () => {
    return (
        <div className="">
            <div className="bg-image card text-white text-center rounded-0">
                <img
                    src={island}
                    className="bgImage card-img img-fluid rounded-0 opacity-75"
                    alt="..."
                />
                <div className="card-img-overlay d-flex align-items-center justify-content-center">
                    <div className="container">
                        <img
                            src={logo}
                            className="card-img img-fluid w-25"
                            alt="..."
                        />
                    </div>
                </div>
            </div>

            <div className="container card bg-transparent text-center rounded-0 mt-3">
                <div className="row p-2">
                    <div className="col-12">
                        <div className="text-center">
                            <div className="card-body bg-light shadow">
                                <img
                                    src={ballWallLogo}
                                    className="card-img img-fluid m-3"
                                    alt="..."
                                    style={{ width: "120px" }}
                                />
                                <h5 className="card-title">Ball Wall</h5>
                                <div className="col">
                                    <p>
                                        Ball Wall is a free mobile game, in
                                        which you use walls to reach the goal.
                                        Can you complete all the levels?
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 p-2 ">
                    <div className="col p-2">
                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-4 text-center p-2 h-100">
                            <div className="card-body bg-light shadow">
                                <div className="container col-md-12 col-lg-8">
                                    <img
                                        className="p-2"
                                        src={stage}
                                        alt=""
                                        height="100"
                                    />
                                    <h5 className="card-title">Beat levels</h5>
                                    <div className="col">
                                        <p>
                                            Complete all levels and master your
                                            skill, once all 10 levels are
                                            completed in a stage, next stage
                                            will be unlocked to prove yourself
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col p-2">
                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-4 text-center p-2 h-100">
                            <div className="card-body bg-light shadow">
                                <div className="container col-md-12 col-lg-8">
                                    <img
                                        className="p-2"
                                        src={coin}
                                        alt=""
                                        height="100"
                                    />
                                    <h5 className="card-title">Get coins</h5>
                                    <div className="col">
                                        <p>
                                            There a plenty of coins across the
                                            levels, try to get as many as you
                                            can, beat your friend's score and
                                            scale thru leaderboard with most
                                            coins earned in the game
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col p-2">
                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-4 text-center p-2 h-100">
                            <div className="card-body bg-light shadow">
                                <div className="container col-md-12 col-lg-8">
                                    <img
                                        className="p-2"
                                        src={iceColors}
                                        alt=""
                                        height="100"
                                    />
                                    <h5 className="card-title">
                                        Progressive dificulty
                                    </h5>
                                    <div className="col">
                                        <p>
                                            Levels are becoming harder to beat
                                            thru progress, but don't hesitate,
                                            you'll become better to clear them
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col p-2">
                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-4 text-center p-2 h-100">
                            <div className="card-body bg-light shadow">
                                <div className="container col-md-12 col-lg-8">
                                    <img
                                        className="p-2"
                                        src={player}
                                        alt=""
                                        height="100"
                                    />
                                    <h5 className="card-title">
                                        Unlock characters
                                    </h5>
                                    <div className="col">
                                        <p>
                                            Spend earned coins to unlock new
                                            characters
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-2 mb-3">
                    <div className="row  text-center p-2 h-100">
                        <div className="col-12 card-body bg-light shadow">
                            <div className="container col-md-12 col-lg-8">
                                <h5 className="col card-title">
                                    Incoming updates
                                </h5>
                                <div className="col">
                                    <p>
                                        New stages, levels, characters, items,
                                        leaderboard section and more! comming
                                        soon
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-2 mb-3">
                    <div className="row  text-center p-2 h-100">
                        <div className="col-12 card-body bg-light shadow">
                            <div className="container col-md-12 col-lg-8">
                                <h5 className="col card-title mb-3">
                                    Gameplay
                                </h5>
                                <div className="col">
                                    <div className="ratio ratio-1x1">
                                        <iframe
                                            src="https://www.youtube.com/embed/_EogyeFqHj4"
                                            title="YouTube video"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-2">
                    <div className="card-body bg-light shadow h-100">
                        <div className="row px-5 text-center">
                            <h5 className="card-title">Get it FREE!</h5>
                            <div className="col-12 p-4">
                                <img
                                    className="img-fluid p-2"
                                    src={iphoneMenu}
                                    alt=""
                                />
                                <img
                                    className="img-fluid p-2"
                                    src={iphoneStage}
                                    alt=""
                                />
                                <img
                                    className="img-fluid p-2"
                                    src={iphoneCharacter}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-sm-12 col-md-4 col-lg-3 text-center">
                                <a
                                    href="https://apps.apple.com/app/id1626158578"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="p-2"
                                        src={downloadAppStore}
                                        alt=""
                                        width="145"
                                    />
                                </a>
                                <h4 className="text-secondary p-2">
                                    READY TO PLAY!
                                </h4>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-3 text-center">
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.keykagames.ballwallgame"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={downloadGooglePlay}
                                        alt=""
                                        width="160"
                                    />
                                </a>
                                <h4 className="text-secondary p-2">
                                    READY TO PLAY!
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-2 text-center">
                    <div className="card-body bg-light shadow">
                        <div className="container col-md-12 col-lg-8">
                            <Link
                                className="text-primary"
                                to="/ballwall/privacy"
                            >
                                Privacy Policy
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowBallWall;
