import React from 'react'
import { Link } from 'react-router-dom'

function Button({ title, link, type, externalLink }) {
    return (
        <>
            {
                externalLink ? (
                    <a href={externalLink} className={type} target="_blank" rel="noopener noreferrer">{title}</a>
                ) : (
                    <Link to={link} className={type}>{title}</Link>
                )
            }
        </>
    )
}

export default Button